import style from './style.module.css';
import { useState } from 'react';
import { ChevronDown, ChevronUp, Plus, Users, Zap } from 'react-feather';
import { useLead } from '@/utils/useLead';
import { useRouter } from 'next/router';
import { Button, Tooltip } from '@nextui-org/react';
import { ListsHorizontalLoader } from './horizontal';
import { ListsVerticalLoader } from './vertical';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { listService } from '@/services/list.service';
import useCustomToasts from '@/utils/useCustomToasts';

const ListHorizontal = dynamic(() => import('./horizontal'), {
  loading: () => <ListsHorizontalLoader />
});

const ListVertical = dynamic(() => import('./vertical'), {
  loading: () => <ListsVerticalLoader />
});

export default function Lists({ horizontal, groupMode, hideHead, onSelect, isCampaigns }) {
  const { openList, setSelectedList, selectedList, lists, mutateLists, updateLists } = useLead();
  const [hide, setHide] = useState(false);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { setToast } = useCustomToasts();

  const isFeed = router.pathname === '/feed';
  const isInbox = router.pathname === '/inbox';
  const isTasks = router.pathname === '/tasks';
  const isPipeline = router.pathname === '/pipeline';


  function handleSelect(list) {
    onSelect && onSelect(list);
    setSelectedList(list ?? null);
    if (!isTasks && !isFeed && !isPipeline && !isCampaigns) {
      router.push('/leads');
    }
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedLists = reorder(
      lists,
      result.source.index,
      result.destination.index
    );


    const ids_lists = reorderedLists.map(list => list.id);


    //update the list order
    updateListOrder(ids_lists);

    mutateLists(reorderedLists, false);
  }


  function updateListOrder(ids_list){
    setLoading(true);
    listService.moveLeadLists(ids_list).then(() => {
      mutateLists();
    }).catch(e => {
      setToast({
        text: 'There was an error updating your list order',
        type: 'error'
      });
    }).finally(() => {
      setLoading(false);
    });
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  return (
    <div className={`${style.wrap} ${horizontal && style.horizontal}`}>
      <div className={style.groupsWrap}>
        {!lists && !horizontal && <ListsVerticalLoader />}
        {!lists && horizontal && <ListsHorizontalLoader />}
        {lists && (
          <div className={style.groups}>
            <div className={'justify-between items-center flex px-2 mb-0.5 w-full'}>
              <div onClick={() => setHide(!hide)}
                   className={'flex rounded-md items-center hover:bg-stone-100 dark:hover:bg-stone-800 p-1 cursor-pointer text-xs uppercase text-stone-400 dark:text-stone-500 font-semibold hover:text-black/80 hover:dark:text-white/80'}>
                <div className={'flex mr-1 items-center justify-center'}>
                  {!hide && <ChevronDown size={16} />}
                  {hide && <ChevronUp size={16} />}
                </div>
                <span>
                  {isCampaigns && 'Campaigns Lists'}
                  {!isCampaigns && 'Lists'}
                </span>
              </div>
              {isCampaigns && (
                <Tooltip placement={'right'} color='invert' content={'Connect a list to the campaign'}>
                  <Button className={'w-5 h-5 min-w-5'} radius={'sm'} variant={'light'} size={'sm'} isIconOnly
                          onClick={() => openList('', 'connect')}
                          type='success' auto>
                    <Plus size={14} />
                  </Button>
                </Tooltip>
              )}
              {!isCampaigns && (
                <Tooltip placement={'right'} color='invert' content={'Create a new list'}>
                  <Button className={'w-5 h-5 min-w-5'} radius={'sm'} variant={'light'} size={'sm'} isIconOnly
                          onClick={() => openList('', 'create')}
                          type='success' auto>
                    <Plus size={14} />
                  </Button>
                </Tooltip>
              )}
            </div>
            {horizontal && (
              <div onClick={() => handleSelect(null)}
                   className={`${style.groupHorizontal} ${style.all} ${!selectedList && style.selected} ${style.noDelete}`}>
                <div className={style.icon}>
                  <Users size={18} />
                </div>
                <div className={style.nameWrap}>
                  <span>{groupMode ? 'All leads' : 'All leads'}</span>
                </div>
              </div>
            )}
            {!hide && (
              <div className={'px-2 flex flex-col gap-0.5'}>
                {!horizontal && (
                  <div onClick={() => handleSelect(null)}
                       className={clsx(
                         !selectedList && 'border-stone-200 dark:border-stone-700 bg-stone-100 dark:bg-stone-800',
                         selectedList && 'hover:border-stone-200 dark:hover:border-stone-700 border-transparent hover:bg-stone-100 hover:text-black dark:hover:text-white dark:hover:bg-stone-800 text-black/70 dark:text-white/80',
                         'text-sm border-1 h-8 px-2 flex items-center flex-row rounded-md cursor-pointer'
                       )}
                  >
                    All leads
                  </div>
                )}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {lists.map((item, index) =>{
                          return (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  {horizontal ?
                                    <ListHorizontal horizontal={horizontal} key={item.id} data={item}
                                                    groupMode={groupMode} /> :
                                    <ListVertical isDragging={snapshot.isDragging} dragHandleProps={provided.dragHandleProps} handleSelect={handleSelect} horizontal={horizontal} key={item.id}
                                                  data={item}
                                                  isCampaigns={isCampaigns} groupMode={groupMode} />
                                  }
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>)}
            {groupMode && (
              <div onClick={() => openList('', 'connect')} className={`${style.groupHorizontal} ${style.button}`}>
                <Zap size={18} style={{ marginRight: 5 }} />
                <div className={style.nameWrap}>
                  <span>Connect list</span>
                </div>
              </div>
            )}
          </div>
        )}
        {/*{!horizontal && (*/}
        {/*  <div onClick={switchSmall} className={`${style.smallModeSwitch} ${small && style.right}`}>*/}
        {/*    <div className={style.arrowWrap}>*/}
        {/*      <ArrowLeft size={22} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{(!hideRight && showArrow && lists) && (*/}
        {/*  <div onClick={() => handleScrollNavigation(600)} className={style.rightArrow}>*/}
        {/*    <ChevronRight />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
}


