import { useLead } from '@/utils/useLead';
import { memo, useState } from 'react';
import { useRouter } from 'next/router';
import style from '@/components/lists/style.module.css';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import useTheme from '@/utils/useTheme';
import { MoreVertical } from 'react-feather';
import ContentLoader from 'react-content-loader';
import lodash from 'lodash';

const ListHorizontal = memo(({ data, readOnly, groupMode, horizontal }) => {
  const { openList, setSelectedList, selectedList } = useLead();
  const [editMode, setEditMode] = useState(false);

  const router = useRouter();

  function handleEditMode() {
    openList(data, 'update');
  }

  function handleDelete() {
    openList(data, 'delete');
  }

  function handleDisconnect() {
    openList(data, 'disconnect');
  }

  let colors = [
    { color: '#EB367F', textColor: '#000' },
    { color: '#F81CE5', textColor: '#000' },
    { color: '#7928CA', textColor: '#000' },
    { color: '#8A63D2', textColor: '#000' },
    { color: '#2cb798', textColor: '#000' },
    { color: '#319a4f', textColor: '#000' },
    { color: '#F7B955', textColor: '#000' },
    { color: '#3291FF', textColor: '#000' }
  ];
  //
  const [selectedColor, setSelectedColor] = useState(colors[Math.floor(Math.random() * colors.length)]);


  function handleDropdown(key) {
    switch (key) {
      case 'edit':
        handleEditMode();
        break;
      case 'delete':
        handleDelete();
        break;
      case 'disconnect':
        handleDisconnect();
        break;
      default:
        break;
    }
  }

  return (
    <div onClick={() => {
      setSelectedList(data);
      !horizontal && router.push('/leads?list=' + data.id);
    }}
         className={`${style.groupHorizontal} ${selectedList?.id === data.id && style.selected} ${!data?.can_be_delete && style.noDelete}`}>
      <div style={{ borderColor: selectedColor?.color }} className={style.icon}>
        <span>{data?.name?.charAt(0)}</span>
      </div>
      <div className={style.nameWrap}>
        <span>{data?.name}</span>
      </div>
      {!editMode && !readOnly && (
        <div className={style.action}>
          <Dropdown>
            <DropdownTrigger>
              <div className={style.actionTrigger}>
                <MoreVertical size={18} />
              </div>
            </DropdownTrigger>
            <DropdownMenu onAction={handleDropdown} aria-label='More options lists'>
              {data?.can_be_delete && !groupMode && (
                <DropdownItem key='edit'>Edit name</DropdownItem>
              )}
              {data?.can_be_delete && groupMode && (
                <DropdownItem key='disconnect'>Disconnect list</DropdownItem>
              )}
              {data?.can_be_delete && !groupMode && (
                <DropdownItem key='delete' color='danger'>Delete</DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      )}
    </div>
  );
}, lodash.isEqual);


export function ListsHorizontalLoader() {
  const { isDark } = useTheme();

  return (
    <div className={style.groups}>
      <ContentLoader
        speed={2}
        width={1000}
        height={41}
        viewBox='0 0 1000 41'
        backgroundColor={isDark ? '#171717' : '#e9ecef'}
        foregroundColor={isDark ? '#343333' : '#c7c7c7'}
      >
        <rect x='0' y='0' rx='20' ry='20' width='200' height='41' />
        <rect x='210' y='0' rx='20' ry='20' width='200' height='41' />
        <rect x='420' y='0' rx='20' ry='20' width='200' height='41' />
        <rect x='630' y='0' rx='20' ry='20' width='200' height='41' />
        <rect x='840' y='0' rx='20' ry='20' width='200' height='41' />
      </ContentLoader>
    </div>
  );
}


export default ListHorizontal;
