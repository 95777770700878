import { useLead } from '@/utils/useLead';
import { memo } from 'react';
import { useRouter } from 'next/router';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger } from '@nextui-org/react';
import style from '@/components/lists/style.module.css';
import { MoreVertical, Menu } from 'react-feather';
import lodash from 'lodash';
import Loading from '@/components/ui/loading';
import clsx from 'clsx';
import Drag from '@/assets/icons/drag.svg';
import { DEFAULT_LIST_EMOJI } from '../../config/app.constant';
import Emoji from '@/components/emoji';

const ListVertical = memo(({ isDragging, data, dragHandleProps, readOnly, groupMode, horizontal, isCampaigns, handleSelect }) => {
  const { openList, selectedList } = useLead();

  function handleEditMode() {
    openList(data, 'update');
  }

  function handleDelete() {
    openList(data, 'delete');
  }

  function handleDisconnect() {
    openList(data, 'disconnect');
  }


  function handleDropdown(key) {
    switch (key) {
      case 'edit':
        handleEditMode();
        break;
      case 'delete':
        handleDelete();
        break;
      case 'disconnect':
        handleDisconnect();
        break;
      default:
        break;
    }
  }

  function NameEditWrap() {
    return (
      <div className={`flex w-full items-center`}>
        <div className={'w-full flex items-center justify-start'}>
          <span className={'text-sm line-clamp-1'}>{data?.name}</span>
        </div>
        {!readOnly && (
          <div className={'ml-auto hidden group-hover:flex'}>
            {dragHandleProps && (
              <div className={clsx('-mr-1.5 text-black items-center justify-center flex h-6 w-6 min-w-unit-6 text-black/60 dark:text-white/80 hover:text-black/100 dark:hover:text-white/100')} {...dragHandleProps}>
                <Drag size={18}/>
              </div>
            )}
            <Dropdown>
              <DropdownTrigger>
                <Button
                  className={clsx('h-6 w-6 min-w-unit-6 text-black/60 dark:text-white/80 hover:text-black/100 dark:hover:text-white/100')}
                  isIconOnly={true} size={'sm'} variant={'light'}>
                  <MoreVertical size={18} />
                </Button>
              </DropdownTrigger>
              <DropdownMenu variant={'faded'} onAction={handleDropdown} aria-label='More options lists'>
                {data?.can_be_delete && !groupMode && (
                  <DropdownItem key='edit'>Edit name</DropdownItem>
                )}
                {data?.can_be_delete && isCampaigns && (
                    <DropdownItem className={'text-warning'} color={'warning'} key='disconnect'>Disconnect
                      list</DropdownItem>
                )}
                {data?.can_be_delete && !groupMode && (
                  <DropdownItem className={'text-danger'} key='delete' color='danger'>Delete</DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>
    );
  }


  return (
    <div onClick={() => handleSelect(data)}
         className={clsx(
           (isDragging || selectedList?.id === data?.id) && 'border-stone-200 dark:border-stone-700 bg-stone-100 dark:bg-stone-800',
           selectedList?.id !== data?.id && 'hover:border-stone-200 dark:hover:border-stone-700 border-transparent hover:bg-stone-100 hover:text-black dark:hover:text-white dark:hover:bg-stone-800 text-black/70 dark:text-white/80',
           data?.can_be_delete && '',
           'group border-1 h-8 pl-2 pr-1.5 flex items-center flex-row rounded-md cursor-pointer'
         )}>
      <div className={'flex items-center leading-none justify-center w-[18px] h-[18px] text-md mr-2'}>
        <Emoji emoji={data?.emoji??DEFAULT_LIST_EMOJI} size={15} />
      </div>
      <NameEditWrap horizontal={horizontal} selected={(selectedList?.id === data.id && style.selected)} />
    </div>
  );
}, lodash.isEqual);


export function ListsVerticalLoader() {
  return (
    <div className={'flex items-center justify-center p-2 mt-2'}>
      <Loading size={'sm'} />
    </div>
  );
}


export default ListVertical;
